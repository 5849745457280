import { cva, type VariantProps } from "./style.utils";

export type LinkVariants = VariantProps<typeof linkVariants>;

export const linkVariants = cva({
  base: [
    // layout
    "rounded-sm",
    // animation
    "transition-colors",
    // focus unset outline
    "focus:outline-none",
    // focus reset outline (keyboard only)
    "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ring",
  ],
  variants: {
    palette: {
      unset: null,
      accent: [
        // foreground
        "text-link-idle",
        // focus/hover/active
        "hover:text-link-hover focus:text-link-focus active:text-link-active",
      ],
      error: [
        // foreground
        "text-error-100",
        // focus/hover/active
        "hover:text-error-80 focus:text-error-80 active:text-error-80",
      ],
      warning: [
        // foreground
        "text-warning-100",
        // focus/hover/active
        "hover:text-warning-80 focus:text-warning-80 active:text-warning-80",
      ],
      success: [
        // foreground
        "text-success-100",
        // focus/hover/active
        "hover:text-success-80 focus:text-success-80 active:text-success-80",
      ],
      neutral: [
        // foreground
        "text-shade-100",
        // focus/hover/active
        "hover:text-shade-80 focus:text-shade-80 active:text-shade-80",
      ],
      "neutral-subtle": [
        // foreground
        "text-shade-70",
        // focus/hover/active
        "hover:text-shade-100 focus:text-shade-100 active:text-shade-100",
      ],
      white: [
        // foreground
        "text-white",
        // focus/hover/active
        "hover:text-white/80 focus:text-white/80 active:text-white/80",
        // focus reset outline (keyboard only)
        "focus-visible:outline-warning-60",
      ],
    },
    size: {
      unset: null,
      "2xs": "text-2xs",
      xs: "text-xs",
      sm: "text-sm",
      md: "text-md",
      h6: "text-h6",
      h5: "text-h5",
      h4: "text-h4",
      h3: "text-h3",
      h2: "text-h2",
      h1: "text-h1 tracking-tight",
    },
    underline: {
      false: [
        // focus/hover/active
        "hover:underline focus:underline active:underline",
        "hover:decoration-1 focus:decoration-1 active:decoration-1",
      ],
      true: [
        // foreground
        "underline decoration-1",
        // focus/hover/active
        "hover:underline-offset-2 focus:underline-offset-2 active:underline-offset-2",
      ],
    },
  },
  defaultVariants: {
    palette: "accent",
    size: "unset",
    underline: true,
  },
});
