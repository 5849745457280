import { Segment } from "#app/(unauthorized)/authentication/jwt";
import { useJwt } from "#app/(unauthorized)/authentication/useJwt";
import { getJson } from "#app/lib/fetchClient";
import { TimeSpan } from "#app/lib/TimeSpan";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import { queryOptions, 
    useQuery 
} from "@tanstack/react-query";

const STALE_TIME = TimeSpan.fromMinutes(1).totalMilliseconds;
const GATEWAY_PATH = "/trust/v1";

export type TrustEligibility = "Market" | "Allow" | "Hide";

export type EligibilityResponse = {
    trust: TrustEligibility;
  };

export function getEligibility(): Promise<EligibilityResponse> {
    return getJson<EligibilityResponse>(`/${GATEWAY_PATH}/User/Eligibility`);
}

export function getSSO(): Promise<string> {
    return getJson<string>(`/${GATEWAY_PATH}/Portal/SSO`);
}

export const trustQueryKeys = createQueryKeys("trust", {
    eligibility: {
      queryKey: null,
      queryFn: () => getEligibility(),
    },
    sso: {
        queryKey: null,
        queryFn: () => getSSO(),
      }
  });

  export function useEligibilityQueryOptions() {
    return 
      queryOptions({
        ...trustQueryKeys.eligibility,
        staleTime: STALE_TIME
      })
  }

  export function useEligibilityQuery() {
    return useQuery(
      queryOptions({
        ...trustQueryKeys.eligibility,
        staleTime: STALE_TIME
      }),
    );
  }

  export function useTrustEligibilityMenuQueryOptions() {
    const jwt = useJwt();
    const isEnabled = jwt.requiredActivities.length == 0 
                      && [Segment.IndividualInvestors, Segment.PrivateAssetManagement].includes(jwt.segment);
    const defaultData = false;

    return queryOptions({
      queryKey: trustQueryKeys.eligibility.queryKey,
      queryFn: (context) =>
        isEnabled
          ? (
            trustQueryKeys.eligibility.queryFn(context) as Promise<EligibilityResponse>
            ).catch((_) => defaultData)
          : defaultData,
      staleTime: Infinity,
      throwOnError: false,
    });
  }

  export function useSsoQuery() {
    return useQuery(
      queryOptions({
        ...trustQueryKeys.sso,
        staleTime: STALE_TIME
      }),
    );
  }
  