import { type NavRoutesConfig } from "#app/_ui/components/headers/nav-routes-config";
import filterHelper from "./filterHelper";
import { type NavRoutesConfigFilterParams } from "./filterNav";

export default function aplQueryFilter({
  navRoutesConfig,
  requiredData,
}: NavRoutesConfigFilterParams): NavRoutesConfig {
  if (!requiredData.hasApl) {
    const filtering = filterHelper(navRoutesConfig);
    return filtering
      .removeRouteSubById("Documents", "DocumentsPerformance")
      .getFilteredRoutesConfig();
  }
  return navRoutesConfig;
}
