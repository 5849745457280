import { Segment } from "#app/(unauthorized)/authentication/jwt";
import { useJwt } from "#app/(unauthorized)/authentication/useJwt";
import { getBlob, getJson } from "@/lib/fetchClient";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import { queryOptions } from "@tanstack/react-query";

export type ResearchHeadline = {
  id: number;
  headlineTitle: string;
  tickerSymbol: string;
  bairdRating: string;
  morningstarRating: string;
  valuelineRating: string;
  spRating: string;
  analyst: string;
  analystId: number | null;
  date: Date;
  suitability: string;
  fileSize: number | null;
  mediaType: string;
  fileName: string;
  deptSect: string;
  deptInd: string;
};

type PageResearhHeadline = {
  bolResearchHeadlines: ResearchHeadline[];
  page: number;
  pageSize: number;
  totalRecordCount: number;
  totalPageCount: number;
};

export type Analyst = {
  analystId: number;
  analystName: string;
};

export type Rating = {
  ratingKey: string;
  companyName: string;
  ratingText: string;
};

export type Sectors = {
  deptIndustryCode: string;
  deptIndustryName: string;
  deptOrInd: string;
};

export type Suitability = {
  suitabilityAbbr: string;
  suitabilityText: string;
  companyName: string;
};

const GATEWAY_PATH = "bairdresearch/v1";

export async function hasAccess(): Promise<boolean> {
  const result: boolean = await getJson(
    `/${GATEWAY_PATH}/ResearchHeadlines/HasAccess`,
  );

  return result;
}
export const bairdResearchQueryKeys = createQueryKeys("research", {
  hasAccess: {
    queryKey: null,
    queryFn: () => hasAccess(),
  },
});

export function useBairdResearchAccessQueryOptions() {
  const jwt = useJwt();
  const isEnabled =
    jwt.requiredActivities.length == 0 &&
    [Segment.IndividualInvestors, Segment.PrivateAssetManagement].includes(
      jwt.segment,
    );
  const defaultData = false;
  return queryOptions({
    queryKey: bairdResearchQueryKeys.hasAccess.queryKey,
    queryFn: (context) =>
      isEnabled
        ? (
            bairdResearchQueryKeys.hasAccess.queryFn(
              context,
            ) as Promise<boolean>
          ).catch((_) => defaultData)
        : defaultData,
    staleTime: Infinity,
    throwOnError: false,
  });
}

export async function getResearchHeadlines(
  tickerSymbol: string,
  headlineText: string,
  deptSect: string,
  analystId: string,
  ratingKey: string,
  suitability: string,
  page: number,
  pageSize: number,
): Promise<PageResearhHeadline> {
  let queryParam: string = `?tickerSymbol=${encodeURIComponent(tickerSymbol)}`;
  queryParam += `&headlineText=${encodeURIComponent(headlineText)}`;
  queryParam += `&deptSect=${encodeURIComponent(deptSect)}`;
  queryParam += `&analystId=${encodeURIComponent(analystId)}`;
  if (ratingKey !== "") queryParam += `&ratingCompany=Baird`;
  queryParam += `&ratingKey=${encodeURIComponent(ratingKey)}`;
  queryParam += `&suitability=${encodeURIComponent(suitability)}`;
  queryParam += `&page=${encodeURIComponent(page)}`;
  queryParam += `&pageSize=${encodeURIComponent(pageSize)}`;

  const result: PageResearhHeadline = await getJson(
    `/${GATEWAY_PATH}/ResearchHeadlines${queryParam}`,
  );

  return result;
}

export async function getDocument(headlineId: number): Promise<Blob> {
  const result: Blob = await getBlob(
    `/${GATEWAY_PATH}/ResearchHeadlines/Document?headlineId=${headlineId}`,
  );

  return result;
}

export async function getAnalysts(): Promise<Analyst[]> {
  const result: Analyst[] = await getJson(
    `/${GATEWAY_PATH}/ResearchHeadlines/Analysts`,
  );

  return result;
}

export async function getRating(): Promise<Rating[]> {
  const result: Rating[] = await getJson(
    `/${GATEWAY_PATH}/ResearchHeadlines/Ratings`,
  );

  return result;
}

export async function getSectors(): Promise<Sectors[]> {
  const result: Sectors[] = await getJson(
    `/${GATEWAY_PATH}/ResearchHeadlines/Sectors`,
  );

  return result;
}

export async function getSuitabilities(): Promise<Suitability[]> {
  const result: Suitability[] = await getJson(
    `/${GATEWAY_PATH}/ResearchHeadlines/Suitabilities`,
  );

  return result;
}
