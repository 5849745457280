import { type NavRoutesConfig } from "#app/_ui/components/headers/nav-routes-config";
import omit from "lodash/omit";

const removeRouteById = (navRoutesConfig: NavRoutesConfig, id: string) =>
  omit(navRoutesConfig, [id]);

const removeRouteSubById = (
  navRoutesConfig: NavRoutesConfig,
  parentId: string,
  id: string,
) => omit(navRoutesConfig, [`${parentId}.paths.${id}`]);

export default function filterHelper(navRoutesConfig: NavRoutesConfig) {
  let filteredRoutesConfig = navRoutesConfig;
  const filterMethods = {
    removeRouteById: (id: string) => {
      filteredRoutesConfig = removeRouteById(filteredRoutesConfig, id);
      return filterMethods;
    },
    removeRouteSubById: (parentId: string, id: string) => {
      filteredRoutesConfig = removeRouteSubById(
        filteredRoutesConfig,
        parentId,
        id,
      );
      return filterMethods;
    },
    getFilteredRoutesConfig: () => filteredRoutesConfig,
  };

  return filterMethods;
}
