import { RequiredData } from "#app/(required)/useRequiredData";
import { Jwt } from "#app/(unauthorized)/authentication/jwt";
import { NavRoutesConfig } from "#app/_ui/components/headers/nav-routes-config";
import flow from "lodash/flow";
import map from "lodash/map";
import allowedSegmentFilter from "./allowedSegmentsFilter";
import clientNavFilter from "./clientNavFilter";
import finPlanningNavFilter from "./finPlanningNavFilter";
import institutionalFilter from "./institutionalFilter";
import notLoggedInNavFilter from "./notLoggedInNavFilter";
import preclientNavFilter from "./preclientNavFilter";
import aplQueryFilter from "./aplQueryFilter";
import trustNavFilter from "./trustNavFilter";

export interface FilterNavRoutesConfigParams {
  jwt: Jwt;
  navRoutesConfig: NavRoutesConfig;
  requiredData: RequiredData;
}

export type NavRoutesConfigFilterParams = FilterNavRoutesConfigParams;

export type NavRoutesConfigFilter = (
  params: NavRoutesConfigFilterParams,
) => NavRoutesConfig;

const filters = [
  allowedSegmentFilter,
  notLoggedInNavFilter,
  preclientNavFilter,
  clientNavFilter,
  institutionalFilter,
  finPlanningNavFilter,
  aplQueryFilter,
  trustNavFilter
] as const satisfies NavRoutesConfigFilter[];

export function filterNavRoutesConfig({
  jwt,
  navRoutesConfig,
  requiredData,
}: FilterNavRoutesConfigParams): NavRoutesConfig {
  const createFilter =
    (filterFn: NavRoutesConfigFilter) => (config: NavRoutesConfig) =>
      filterFn({ navRoutesConfig: config, jwt, requiredData });

  return flow(map(filters, createFilter))(navRoutesConfig) as NavRoutesConfig;
}
