import { useRequiredData } from "#app/(required)/useRequiredData";
import { Segment } from "#app/(unauthorized)/authentication/jwt";
import { useJwt } from "#app/(unauthorized)/authentication/useJwt";
import { filterNavRoutesConfig } from "#app/lib/route-filters/filterNav";
import { parseRoutesConfig, type NavRoutes } from "#ui/nav.utils";
import { NAV_ROUTES_CONFIG } from "./nav-routes-config";

export function useNavRoutes() {
  const jwt = useJwt();
  const { isPendingRequiredData, requiredData } = useRequiredData();

  const isUnauthenticated = jwt.segment == Segment.NotLoggedIn;
  const hasRequiredActivities = jwt.requiredActivities.length > 0;
  const isPendingRequirements = isPendingRequiredData || hasRequiredActivities;

  // let routesUnfiltered: NavRoutes = [];
  let routes: NavRoutes = [];
  if (!isUnauthenticated && !isPendingRequirements) {
    const navRoutesConfigFiltered = filterNavRoutesConfig({
      jwt,
      navRoutesConfig: NAV_ROUTES_CONFIG,
      requiredData,
    });
    // routesUnfiltered = parseRoutesConfig(NAV_ROUTES_CONFIG);
    routes = parseRoutesConfig(navRoutesConfigFiltered);
  }

  // TODO: refactor nav route extractions
  const routesPrimary = routes.filter(
    (r) =>
      ![
        "ProfileAndSettings",
        "MyTeam",
        "SignIn",
        "SignOut",
        "RegisterClient",
        "RegisterProspect",
      ].includes(r.id),
  );
  return {
    mobileOnly: routesPrimary,
    desktopOnly: routesPrimary,
    Home: routes.find((r) => ["Home", "HomePreclient"].includes(r.id))!,
    DocumentsReceived: routes
      .find((r) => r.id === "Documents")
      ?.paths?.find((r) => r.id === "DocumentsReceived")!,
    ProfileAndSettings: routes.find((r) => r.id === "ProfileAndSettings")!,
    MyTeam: routes.find((r) => r.id === "MyTeam")!,
    SignOut: routes.find((r) => r.id === "SignOut")!,
  };
}
