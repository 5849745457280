import NextLink from "next/link";
import { forwardRef } from "react";
import {
  buttonCloseResponsiveVariants,
  buttonCloseVariants,
  buttonIndicatorVariants,
  buttonVariants,
  buttonWithIndicatorVariants,
  type ButtonCloseResponsiveVariants,
  type ButtonCloseVariants,
  type ButtonVariants,
  type ButtonWithIndicatorVariants,
} from "./button.styles";
import { Icon } from "./icon";
import { linkVariants, type LinkVariants } from "./link.styles";

// -----------------------------------------------------------------------------

export type AnchorRef = React.ElementRef<"a">;

export interface AnchorProps
  extends React.ComponentPropsWithoutRef<"a">,
    LinkVariants {}

const Anchor = forwardRef<AnchorRef, AnchorProps>(
  ({ className, palette, size, underline, ...props }, ref) => {
    return (
      <a
        {...props}
        className={linkVariants({ className, palette, size, underline })}
        ref={ref}
      />
    );
  },
);

Anchor.displayName = "Anchor";

export { Anchor as A, Anchor };

// -----------------------------------------------------------------------------

export type AnchorButtonRef = React.ElementRef<"a">;

export interface AnchorButtonProps
  extends React.ComponentPropsWithoutRef<"a">,
    ButtonVariants {}

const AnchorButton = forwardRef<AnchorButtonRef, AnchorButtonProps>(
  (
    { className, iconOnly, palette, size, underline, variant, ...props },
    ref,
  ) => {
    return (
      <a
        {...props}
        className={buttonVariants({
          className,
          iconOnly,
          palette,
          size,
          underline,
          variant,
        })}
        ref={ref}
      />
    );
  },
);

AnchorButton.displayName = "AnchorButton";

export { AnchorButton };

// -----------------------------------------------------------------------------

export type LinkRef = React.ElementRef<typeof NextLink>;

export interface LinkProps
  extends React.ComponentPropsWithoutRef<typeof NextLink>,
    LinkVariants {}

const Link = forwardRef<LinkRef, LinkProps>(
  ({ className, palette, size, underline, ...props }, ref) => {
    return (
      <NextLink
        {...props}
        className={linkVariants({ className, palette, size, underline })}
        ref={ref}
      />
    );
  },
);

Link.displayName = "Link";

export { Link };

// -----------------------------------------------------------------------------

export type LinkButtonRef = React.ElementRef<typeof NextLink>;

export interface LinkButtonProps
  extends React.ComponentPropsWithoutRef<typeof NextLink>,
    ButtonVariants {}

const LinkButton = forwardRef<LinkButtonRef, LinkButtonProps>(
  (
    { className, iconOnly, palette, size, underline, variant, ...props },
    ref,
  ) => {
    return (
      <NextLink
        {...props}
        className={buttonVariants({
          className,
          iconOnly,
          palette,
          size,
          underline,
          variant,
        })}
        ref={ref}
      />
    );
  },
);

LinkButton.displayName = "LinkButton";

export { LinkButton };

// -----------------------------------------------------------------------------

export type LinkButtonWithIndicatorRef = React.ElementRef<typeof NextLink>;

export interface LinkButtonWithIndicatorProps
  extends React.ComponentPropsWithoutRef<typeof NextLink>,
    Omit<ButtonWithIndicatorVariants, "activeIndicator"> {
  count: number;
}

const LinkButtonWithIndicator = forwardRef<
  LinkButtonWithIndicatorRef,
  LinkButtonWithIndicatorProps
>(
  (
    {
      children,
      className,
      count = 0,
      iconOnly,
      palette,
      size,
      underline,
      variant,
      ...props
    },
    ref,
  ) => {
    const active = count > 0;
    return (
      <NextLink
        {...props}
        className={buttonWithIndicatorVariants({
          className,
          activeIndicator: active,
          iconOnly,
          palette,
          size,
          underline,
          variant,
        })}
        ref={ref}
      >
        {children}
        <span className={buttonIndicatorVariants({ active })}>
          <span className="sr-only">{count}</span>
        </span>
      </NextLink>
    );
  },
);

LinkButtonWithIndicator.displayName = "LinkButtonWithIndicator";

export { LinkButtonWithIndicator };

// -----------------------------------------------------------------------------

export type LinkButtonCloseRef = React.ElementRef<typeof NextLink>;

export interface LinkButtonCloseProps
  extends Omit<React.ComponentPropsWithoutRef<typeof NextLink>, "children">,
    ButtonCloseVariants {}

const LinkButtonClose = forwardRef<LinkButtonCloseRef, LinkButtonCloseProps>(
  ({ className, ...props }, ref) => {
    return (
      <NextLink
        {...props}
        className={buttonCloseVariants({ className })}
        ref={ref}
      >
        <Icon name="ms-close" />
        <span className="sr-only">Close</span>
      </NextLink>
    );
  },
);

LinkButtonClose.displayName = "LinkButtonClose";

export { LinkButtonClose };

// -----------------------------------------------------------------------------

export type LinkButtonCloseResponsiveRef = React.ElementRef<typeof NextLink>;

export interface LinkButtonCloseResponsiveProps
  extends React.ComponentPropsWithoutRef<typeof NextLink>,
    ButtonCloseResponsiveVariants {}

const LinkButtonCloseResponsive = forwardRef<
  LinkButtonCloseResponsiveRef,
  LinkButtonCloseResponsiveProps
>(({ children, className, ...props }, ref) => {
  return (
    <NextLink
      {...props}
      className={buttonCloseResponsiveVariants({ className })}
      ref={ref}
    >
      <Icon name="ms-close" className="sm:hidden" />
      <span className="max-sm:sr-only">{children}</span>
    </NextLink>
  );
});

LinkButtonCloseResponsive.displayName = "LinkButtonCloseResponsive";

export { LinkButtonCloseResponsive };
