import { Segment } from "#app/(unauthorized)/authentication/jwt";
import { type NavRoutesConfig } from "#app/_ui/components/headers/nav-routes-config";
import filterHelper from "./filterHelper";
import { type NavRoutesConfigFilterParams } from "./filterNav";

export default function clientNavFilter({
  jwt,
  navRoutesConfig,
  requiredData,
}: NavRoutesConfigFilterParams): NavRoutesConfig {
  if (
    jwt.segment == Segment.IndividualInvestors ||
    jwt.segment == Segment.PrivateAssetManagement
  ) {
    let filtering = filterHelper(navRoutesConfig);
    filtering = filtering.removeRouteById("HomePreclient");

    if (requiredData.hasBairdResearch == false)
      filtering = filtering.removeRouteSubById(
        "InvestingTools",
        "InvestingToolsBairdResearch",
      );

    navRoutesConfig = filtering.getFilteredRoutesConfig();
  }

  return navRoutesConfig;
}
