import { type NavRoutesConfig } from "#app/_ui/components/headers/nav-routes-config";
import filterHelper from "./filterHelper";
import { type NavRoutesConfigFilterParams } from "./filterNav";

export default function trustNavFilter({
    navRoutesConfig,
    requiredData,
  }: NavRoutesConfigFilterParams): NavRoutesConfig {

    let filtering = filterHelper(navRoutesConfig);

    if (!requiredData.canUseTrust) {
      filtering = filtering.removeRouteById(
        "BairdTrust",
      );
    }
  
    return filtering.getFilteredRoutesConfig();
  }
  